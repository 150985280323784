<template>
	<div class="warp widthMin">
			<!--资产保全服务-->
			<div class="m_z mb-3">
				<div class="m_z_title">
					<h2 class="text-center mb-2 pt-md-5">资产管理保全服务</h2>
				    <h4 class="text-center mb-4">Data center</h4>
				</div>
				<div class="m_z_btns">
					<button>资产管理保全  数据中心</button>
					<button>资产管理保全  客户端</button>
				</div>
				<div class="m_z_text pt-md-4">
					<p>为资方提供整套的资产保全服务方案</p>
				    <p>还款现状便于了解拆迁项目 “当前还款进度”；债资比可以清晰的了解“当前资产预估金额/剩余资产金额”是否可控；</p>
				    <p>资产数据清晰了解 “哪些资产出现异常” “拆迁项目的资产异常率”</p>
				</div>
				<div class="m_z_btn_e pt-md-3">
					<img :src="rz_m_1" />
				</div>
				<div class="m_z_b_title">
					<button>
						<img src="../../assets/images/web_h5/m_2.png" />
						<span>现在去融资</span>
					</button>
				</div>
				<div class="pt-md-5 m_z_tip">简单填写项目资料、即可完成申请</div>
			</div>
			
			<!--资产管理保全为您的资产保驾护航-->
			<div class="m_z mb-3 m_b">
				<div class="m_z_title">
					<h2 class="text-center mb-2 pt-md-4">资产管理保全为您的资产保驾护航</h2>
				    <h4 class="text-center mb-5">Smart Part</h4>
				</div>
				<div class="m_b_btns">
					<button>资产管理保全控制中心</button>
					<button>拆迁方资产管理服务端</button>
					<button>进出车辆控制计划</button>
					<button>RFID仓库自动盘点计划</button>
				</div>
				<div class="m_b_btns mt-2">
					<button>GPS模组电子围栏控制计划</button>
					<button>高频有源定位盘点计划</button>
					<button>基于LBS的资产管理</button>
					<button>园区摄像头记录等等</button>
				</div>
				<div class="m_b_info pt-md-5">
					<div class="m_b_info_l">
						<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					</div>
					
					<div class="m_b_info_r">
						<div class="info_box">
							<h2>资产管理保全控制中心</h2>
							<h3>为您的资产和入资保驾护航</h3>
							<div class="text">资产管理保全控制中心，将会实时通过布置在智慧拆迁园区内的设备自动读取贴在拆迁物上的资产标签(GPS、 RFID、高频定位标签、蓝牙WIFI等) ，自 动进行数据解析,并在中央数据中心进行比对。通过数据算法，自动分析当前拆迁设备及资产所在位置，当前的状态，是否已卖出、是否被认为挪动位置等。从而第一时间发现资产异动，并通过该保全控制中心下发风险提示给柚关工作人员、资方及拆迁方。盘点的频率最低为5秒一次能在设备出现异常的第- -时间发现并报警，以给相关风控工作提供足够的时间。</div>
						    <div class="text">当债资比(剩余资产价值/总融资金额)小于风控制，或发生高价值资产异常、异常数据达到一定阀值等情况时，保全控制中心将自动锁死出入口道闸，防止大型设备随车辆流出。</div>
						</div>
					</div>
				</div>
				
				<div class="m_z_b_title ">
					<button>
						<img src="../../assets/images/web_h5/m_2.png" />
						<span>现在去融资</span>
					</button>
				</div>
				<div class="pt-md-5 m_z_tip">简单填写项目资料、即可完成申请</div>
			</div>
			
				
			<!--智慧拆迁园区建设-->
			<div class="m_y">
				<div class="m_y_step">
					<h2>资产管理保全控制中心</h2>
					<h3>为您的资产和入资保驾护航</h3>
					<ul>
						<li class="active">
							<div></div>
							<p>智能道闸应用</p>
						</li>
						<li>
							<div></div>
							<p>RFID标签</p>
						</li>
						<li>
							<div></div>
							<p>RFID 4-16通道盘点器</p>
						</li>
						<li>
							<div></div>
							<p>RFID龙门道闸及线极化天线</p>
						</li>
						<li>
							<div></div>
							<p>RFID仓库自动圆极化盘点天线</p>
						</li>
						<li>
							<div></div>
							<p>GPS防拆卸模组</p>
						</li>
						<li>
							<div></div>
							<p>GPS电子围栏</p>
						</li>
						<li>
							<div></div>
							<p>有源定位标签</p>
						</li>
						<li>
							<div></div>
							<p>自动盘点定位读卡器</p>
						</li>
						<li>
							<div></div>
							<p>自动盘点定位位置服务器</p>
						</li>
						<li>
							<div></div>
							<p>wifI设备</p>
						</li>
						<li>
							<div></div>
							<p>手持式扫描器</p>
						</li>
						<li>
							<div></div>
							<p>等等其他</p>
						</li>
					</ul>
				</div>
				<div class="m_y_img">
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
					<img class="h_img" src="../../assets/images/web_h5/a_1.png" />
				</div>
			</div>
		</div>
</template>

<script>
	export default {
		data() {
			return {
				rz_m_1:this.imgBasUrl2+'rz_m_1.png',
			}
		},
		methods: {
		},
	};
</script>

<style>
	@import url('../../assets/css/web_h5/manage.css');
</style>